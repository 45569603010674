<template>
  <div class="noun_interpretation">
    <div class="content">
      <b>累计赚佣：</b>
      <p>
        包含“待结算佣金”和“已结算佣金”两部分，本人直接向买家推广商品，买家付款成功后形成推广订单，所有推
        广订单均带来佣金收入。
      </p>
      <b>待结算佣金：</b>
      <p>
        已付款的推广订单，可计算预估佣金，为待结算状态，在结算之前可能因买家退款等原因产生波动，实际结算金额以您钱包账户内实际收到的金额为准。
      </p>
      <b>已结算佣金：</b>
      <p>
        买家付款后7个工作日，预估佣金转为已结算状态，自动转入钱包账户余额，并支持提现到银行卡，在结算之前发生买家退款，不分佣。
      </p>
      <b>推广订单：</b>
      <p>成功推广的订单，不包含退款订单。</p>
      <b>推广业绩：</b>
      <p>推广员本人直接向买家推广商品，买家购买商品的实付 金额累计。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "noun-interpretation",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.noun_interpretation {
  padding-top: 46px;
  .content {
    padding: 24px 15px;
    background: #fff;

    b {
      font-size: 16px;
      color: #333333;
      line-height: 22px;
    }
    p {
      font-size: 14px;
      color: #666666;
      margin-top: 16px;
      margin-bottom: 48px;
    }
  }
}
</style>
